main {
  margin: auto;
  font-size: 20px;
  line-height: 1.6;
}

.hero {
  /* background-color: #4d28d2; */
  min-height: calc(100vh);
  padding: 40px;
  color: white;
  margin: 0;
}

.hero-1st-child {
  max-width: 900px;
  width: 100%;
  margin:auto
}

h1 {
  font-size: 4.5rem;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  margin-bottom: 1em;
}

h2 {
  font-size: 3rem;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  margin-bottom: 1em;
}

span {
  font-size: 1rem;
  font-weight: 300;
}

.text-gradient {
  background-image: linear-gradient(45deg, #0046f5, #F9E8C9 30%, white 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 4.5rem;
  line-height: 1;
  font-weight: 600;
  margin-bottom: 1em;
  background-size: 400%;
  background-position: 0%;
}

.file-type {
  width: 200px;
  height: auto;
  border: 1px solid lightgrey;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
  text-align: center;
}

.file-type-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.file-type-box-group {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

@media screen and (max-width: 600px) {

}